<template>
    <div class="information">
        <div class="information-box">
            <div class="information-box-list">
                <div class="information-box-list-left">
                    <img
                        class="img"
                        src="https://img0.baidu.com/it/u=1250551608,2180019998&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1670778000&t=87cbdefa412ee23efa24a2f73e434484"
                    />
                    <p class="user-p">
                        <span class="s1">{{ userInfo.account }}</span>
                        <span class="s2" v-if="userInfo.jjdUserId"
                            >加盟余额：{{ userInfo.jjdPayBalance || 0 }}
                            <!-- <i class="el-icon-sort"></i> -->
                            ，赠送余额：{{ userInfo.jjdPayBalanceGive || 0 }}
                            <!-- <i class="el-icon-sort"></i> -->

                            ，预授权额度：{{
                                userInfo.jjdPreAuthorizationBalance || 0
                            }}
                            <!-- <i class="el-icon-sort"></i> -->
                        </span>
                        <span class="s2" v-else
                            >额度：{{ userInfo.point || 0 }}</span
                        >
                    </p>
                </div>
                <div class="information-box-list-right">
                    <p class="btn">编辑</p>
                </div>
            </div>
            <div class="information-box-list">
                <div class="information-box-list-left">
                    <p class="text">手机</p>
                    <div class="phone-data">
                        <img src="../../static/images/user/2.png" alt="" />
                        <div class="status">
                            <!-- <p class="s1">
                                <span class="ss1">已绑定：</span>
                                <span class="ss2">158****140</span>
                            </p> -->
                            <p class="s2">
                                可用该手机号登录所有火键产品，包括app,小程序，电脑数据系统等...
                            </p>
                        </div>
                    </div>
                </div>
                <div class="information-box-list-right">
                    <p class="editor-phone">更换手机</p>
                </div>
            </div>

            <!-- <div class="information-box-list">
                <div class="information-box-list-left">
                    <p class="text">邮箱</p>
                    <div class="phone-data">
                        <img src="../../static/images/user/6.png" alt="" />
                        未绑定
                    </div>
                </div>
                <div class="information-box-list-right">
                    <p class="btn">立即绑定</p>
                </div>
            </div> -->

            <div class="information-box-list">
                <div class="information-box-list-left">
                    <p class="text">角色</p>
                    <div class="phone-data">
                        <img src="../../static/images/user/6.png" alt="" />
                        待定
                    </div>
                </div>
                <div class="information-box-list-right">
                    <p class="btn">立即加入</p>
                </div>
            </div>

            <div class="information-box-list">
                <div class="information-box-list-left">
                    <p class="text">所在团队</p>
                    <div class="phone-data">
                        <img src="../../static/images/user/6.png" alt="" />
                        未加入
                    </div>
                </div>
                <div class="information-box-list-right">
                    <p class="btn">立即加入</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getUSer } from "@/api/user";
export default {
    name: "user_info",

    data() {
        return {
            userInfo: "",
        };
    },

    mounted() {
        getUSer().then((res) => {
            // console.log(res.data);
            this.userInfo = res.data;
        });
    },

    methods: {},
};
</script>


<style lang="less" scoped>
/deep/ .el-icon-sort {
    cursor: pointer;
}
.information-box-list:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.information-box-list + .information-box-list {
    margin-top: 2px;
}
.information {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 20px;

    &-box {
        &-list {
            background-color: #fff;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            &-left {
                display: flex;
                align-items: center;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 18px;
                }

                .user-p {
                    display: flex;
                    flex-direction: column;

                    .s1 {
                        color: #010101;
                        font-size: 16px;
                    }

                    .s2 {
                        color: #010101;
                        opacity: 0.3;
                        font-size: 14px;
                    }
                }

                .text {
                    width: 100px;
                    color: #010101;
                    opacity: 0.7;
                    font-size: 15px;
                }

                .phone-data {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    color: #010101;

                    img {
                        width: 25px;
                        height: 25px;
                    }

                    .status {
                        .s1 {
                            display: flex;

                            span {
                                font-size: 14px;
                            }

                            .ss1 {
                                color: #010101;
                                opacity: 0.7;
                            }

                            .ss2 {
                                color: #76bfff;
                            }
                        }

                        .s2 {
                            color: #010101;
                            opacity: 0.7;
                            font-size: 14px;
                            margin-top: 3px;
                        }
                    }
                }
            }

            &-right {
                .btn {
                    width: 90px;
                    height: 43px;
                    line-height: 43px;
                    background: #349ffd;
                    color: #fff;
                    border-radius: 6px 6px 6px 6px;
                    opacity: 1;
                    text-align: center;
                    cursor: pointer;
                    font-size: 14px;
                }

                .editor-phone {
                    color: #349ffd;
                    font-size: 13px;
                    font-weight: 800;
                    position: relative;
                    right: 10px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>